

























import { Component, Vue } from "vue-property-decorator";
import { UserService, GaodeService } from "src/services";
import { Toast } from "vant";
import { tools } from "src/utils";
import { ILogin } from "src/model";

@Component
export default class Login extends Vue
{
    /**
     * 登录数据模型
     * @private
     * @returns ILogin
     */
    private modelData: ILogin =
    {
        code: "", // 企业编号
        phone: "", // 登入账号
        password: "" // 登入密码
    };

    /**
     * 是否显示密码
     * @private
     * @returns string
     */
    private showPwd: boolean = false;

    /**
     * 获取当前登入机构信息
     * @private
     * @returns void
     */
    private get orgInfo(): any
    {
        return this.$store.getters.orgInfo || {};
    }

    /**
     * 跳转修改密码页面
     * @private
     * 
     * @returns void
     */
    private async onFindPassword(): Promise<void>
    {
        this.$router.push({name: "updatePwd",query: {name: "find"}});
    }

    /**
     * 登录
     * @private
     * @returns void
     */
    private async onLogin(): Promise<void>
    {
        try
        {

            let {content: result} =  await UserService.instance.login(this.modelData);

            if(result.data)
            {
                this.$store.dispatch("setUserInfo", result.data);
                this.$router.push({name: "home"});
            }
        }
        catch(err)
        {
            Toast(err);
        }
    }

    /**
     * 组件创建钩子
     * @private
     * @returns void
     */
    private created(): void
    {
        localStorage.removeItem("openid")
        this.modelData.code = localStorage.getItem("appid");
        this.getOrgIngo(this.modelData.code);
    }
    
    /**
     * 获取机构详情
     * @private
     * @returns void
     */
    private async getOrgIngo(code: string): Promise<void>
    {
        try
        {

            let {content: result} =  await UserService.instance.getOrgIngo(code);

            if(result.data)
            {
                this.$store.dispatch("setOrgInfo", result.data);
                this.getGeolocation();
            }
        }
        catch(err)
        {
            Toast(err);
        }
    }

    /* 获取当前位置信息
     * @private
     * @returns void
     */
    private async getGeolocation(): Promise<void>
    {
        this.$store.dispatch("setLocation", null);
        let data = await GaodeService.instance.getGeolocation();
        this.$store.dispatch("setLocation", data);
    }

}
